"use client";

import { createContext } from "react";

interface IsMobileContextProps {
  isMobile: boolean;
}

const IsMobileContext = createContext<IsMobileContextProps>({
  isMobile: false,
});

export default IsMobileContext;
