"use client";

import React, { ReactNode, useState } from "react";

import IsMobileContext from "./IsMobileContext";

// The following code is not used in the app right now. In case we ever want to change the isMobile flag based on the
// client side window size, we can use this code.

// export const useWindowSize = () => {
//   const [windowSize, setWindowSize] = useState<{
//     width?: number;
//     height?: number;
//   }>({
//     width: undefined,
//     height: undefined,
//   });
//
//   useEffect(() => {
//     function handleResize() {
//       setWindowSize({
//         width: window.innerWidth,
//         height: window.innerHeight,
//       });
//     }
//
//     window.addEventListener("resize", handleResize);
//
//     // Call handler right away so state gets updated with initial window size
//     handleResize();
//
//     // Don't forget to remove event listener on cleanup
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);
//
//   return windowSize;
// };

const IsMobileProvider = ({
  children,
  defaultSsrIsMobile,
}: {
  children: ReactNode;
  defaultSsrIsMobile?: boolean;
}) => {
  const [isMobile] = useState<boolean>(defaultSsrIsMobile || false);

  // The following code is not used in the app right now. In case we ever want to change the isMobile flag based on the
  // client side window size, we can use this code.

  // const { width: windowWidth } = useWindowSize();
  //
  // useEffect(() => {
  //   if (!windowWidth) return;
  //
  //   const isBrowserMobile = !!windowWidth && windowWidth < 768;
  //
  //   setIsMobile(isBrowserMobile);
  // }, [windowWidth]);

  return (
    <IsMobileContext.Provider
      value={{
        isMobile,
      }}
    >
      {children}
    </IsMobileContext.Provider>
  );
};

export default IsMobileProvider;
