import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/apps/public/src/globals.css");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/node_modules/antd/lib/config-provider/index.js");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"]}],\"variableName\":\"openSans\"}");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/packages/hooks/src/AntdRegistryProvider/AntdRegistryProvider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/packages/hooks/src/Auth/AuthContext.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/packages/hooks/src/Auth/AuthProvider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/packages/hooks/src/Auth/useAuth.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/packages/hooks/src/ChatWebSocket/ChatWebSocketProvider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/packages/hooks/src/ChatWebSocket/useChatWebSocket.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/packages/hooks/src/GoogleReCaptchaProvider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/packages/hooks/src/GraphQLRequestHook.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/packages/hooks/src/IsMobile/IsMobileContext.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/packages/hooks/src/IsMobile/IsMobileProvider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/packages/hooks/src/IsMobile/useIsMobile.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/packages/hooks/src/Message/MessageHook.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/packages/hooks/src/Modal/ModalHook.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/packages/hooks/src/NotificationWebSocketProvider/NotificationWebSocketContext.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/packages/hooks/src/NotificationWebSocketProvider/NotificationWebSocketProvider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/packages/hooks/src/NotificationWebSocketProvider/useNotificationWebSocket.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/packages/hooks/src/SessionWebSocket/SessionWebSocketHook.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/packages/hooks/src/Theme/ThemeContext.ts");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/packages/hooks/src/Theme/ThemeProvider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/packages/hooks/src/Theme/useTheme.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/packages/hooks/src/useCookie.ts");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/packages/hooks/src/useElementOnScreen.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/packages/hooks/src/useLocalStorage.ts");
import(/* webpackMode: "eager" */ "/home/runner/work/react-koble/react-koble/packages/ui/src/CommonFacebook/CommonFacebookPixelScript.tsx")